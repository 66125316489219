// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false
};

export const firebaseConfig = {
  apiKey: "AIzaSyCT1VvuRpIdIFEe-zxSHdV-AXpDAwXn5JY",
  authDomain: "custom-store-86c8e.firebaseapp.com",
  projectId: "custom-store-86c8e",
  storageBucket: "custom-store-86c8e.appspot.com",
  messagingSenderId: "472313332662",
  appId: "1:472313332662:web:d644eb8b351898c0cff717",
  measurementId: "G-96NB5C4Z0S"
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
