import { Component, OnInit, ViewEncapsulation, ChangeDetectorRef} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MenuController, Platform } from '@ionic/angular';
import { LanguagesService } from '../services/languages/languages.service';
import { ThemeService } from '../services/theme/theme.service';
import { AppComponent } from '../app.component';
import { Sanitizer } from '@angular/core';
import { Router } from '@angular/router';
import { registerLocaleData } from '@angular/common';
import es from '@angular/common/locales/es';

@Component({
  selector: 'app-folder',
  templateUrl: './folder.page.html',
  styleUrls: ['./folder.page.scss'],
  encapsulation: ViewEncapsulation.None
})

export class FolderPage implements OnInit {
  public folder: string;
  public itemsOnCart = 10;
   products: any = []; productsTop: any = [];
  isSearch = false; isSearchbarOpen = false;
  searching = false; suggestSearch: any = [];
  notFound = false;
  subProduct: any = [];
  imagesSubProduct: any = [];

  test = '--background: #fff url("https://cdn.pixabay.com/photo/2017/09/09/18/25/living-room-2732939_960_720.jpg") no-repeat center center / cover;';
  constructor(private activatedRoute: ActivatedRoute, 
              private menuController: MenuController, 
              private lenguages: LanguagesService,
              private theme: ThemeService,
              private cdr: ChangeDetectorRef,
              private appComponent: AppComponent,
              private sanitizer: Sanitizer,
              private router: Router,
              private platform: Platform) { 
                this.platform.keyboardDidHide.subscribe(() => {
                  let element: any = document.getElementById('bt-fx');
                  element.focus();
                });

                // this.platform.backButton.subscribeWithPriority(0, () => {
                //   alert('back is pressed');
                // });
              }

  ngOnInit() {
    this.folder = this.activatedRoute.snapshot.paramMap.get('id');
    this.viewProducts();
    registerLocaleData(es);
  }

  
  ionViewDidEnter(){
    setTimeout(() => {
      let winMobile = document.getElementById('mobile-window');
      let sidePcMenu = document.getElementById('side-pc-menu');
      let containerSidePcMenu = document.getElementById('side-pc-menu');
      // if(this.appComponent.sideMenuMobile_v1) {
      //   winMobile.style.width = '100%';
      //   sidePcMenu.style.width = '0%';
      //   sidePcMenu.style.maxWidth = '0%';
      //   containerSidePcMenu.className = 'clear-borders';
      // } else {
      //   winMobile.style.width = '86%';
      //   sidePcMenu.style.width = '14%';
      //   sidePcMenu.style.maxWidth = '14%';
      // }

      let swip: any = document.getElementById('principal-swiper');
      swip.swiper.disable();
    }, 30);
  }

  public getMenuButtonPC(type) {
    let menu = document.getElementById('side-pc-menu');
    console.log(menu.className);
    let windowpc = document.getElementById('mobile-window');
    if(type) {
      // menu.className = 'clear-borders container-menu-v1 c-m-v1-close md hydrated';
      // windowpc.className = 'clear-borders content-m-v1-close';
    } else {
      // menu.className = 'clear-borders container-menu-v1 c-m-v1-open md hydrated';
      // windowpc.className = 'clear-borders content-m-v1-open';
    }
  } 

  searchProduct(value, type) {
    this.searching = true;
    this.notFound = false;
    this.products = [];
    this.appComponent.callSearch(value, type).then((result) => {
      this.products = result;
      if (this.products.length === 0) {
        this.notFound = true;
      } else {
        this.notFound = false;
      }
      this.searching = false;
      this.isSearch = true;
    }).catch(err => console.log(err));
    
    if(!this.isSearch) {
      this.hideSlider();
      this.hideHighPriority();
    }
  }

  cancelSearch(ev) {
    if(this.isSearch) {
      //this.viewProducts();
    }
  }

  triggerSearch(value, type) {
    if(value !== '') {
      let element: any = document.getElementById('bt-fx');
      element.focus();
      
      if(this.folder !== 'Home') {
        this.router.navigate(['Store/Home']);
        setTimeout(() => {
          this.searchProduct(value, type);
        }, 100);
      } else {
        this.searchProduct(value, type);
      }
    }
  }

  focusSearchbar(open) {
    let header = document.getElementById('header-custom');
    if(open) {
      header.className = 'custom-header header-expand';
      this.isSearchbarOpen = true;
    } else {
      header.className = 'custom-header header-shorten';
      
      setTimeout(() => {
        this.isSearchbarOpen = false;
        this.suggestSearch = [];
      }, 100);

      setTimeout(() => {
        header.className = 'custom-header';
        this.suggestSearch = [];
      }, 500);
    }
  }

  public viewProducts() {
    if(this.isSearch) {
      let slide = document.getElementById('principal-slide');
      slide.className = 'cont-slides';
    }
    this.isSearch = false;
    this.products = [];
    this.productsTop = [];
    this.searching = true;
    this.appComponent.getProducts().then((result) => {
      this.products = result;
      let container: any = [];
      for (let i of this.products ) {
        if(i.top === undefined) {
          container.push(i);
        }
      }
      this.products = container;
    }).catch(err => console.log(err));

    this.appComponent.getProductsTop().then((result) => {
      this.productsTop = result;
      this.searching = false;
    }).catch(err => console.log(err));
  }

  viewSubproduct(data) {
    this.subProduct = data;
    this.imagesSubProduct = this.subProduct.images;
    this.navigateSwiper(true);
  }

  async navigateSwiper(to) {
    let swip: any = document.getElementById('principal-swiper');
    await swip.swiper.enable();
    if(to) {
      await swip.swiper.slideNext();
    } else {
      await swip.swiper.slidePrev();
    }
    swip.swiper.disable();
  }


  hideSlider() {
    let slide = document.getElementById('principal-slide');
    slide.className = 'cont-slides cont-slides-anim';
  }

  hideHighPriority() {
    let cards = document.getElementById('high-priority');
    cards.className = 'hide-high-priority';
  }

  addProductIndices() {
    this.appComponent.addProduct();
  }

  getMenuButton() {
    document.getElementById('menu-button-topMenuMobile').click();
  }

  getShoppingCartButton () {
    document.getElementById('menu-button-purchase-cart').click();
  }

  getSuggestSearch(e) {
    if(e.detail.value !== '' && e.detail.value.length > 1) {
      const data = [
        {
          name: 'Pc gamer', icon: 'search-outline'
        },
        {
          name: 'Computadora portátil', icon: 'search-outline'
        },
        {
          name: 'Computadora gamer', icon: 'search-outline'
        },
        {
          name: 'Mouse gamer', icon: 'search-outline'
        },
        {
          name: 'Mouse retroiluminado', icon: 'search-outline'
        },
        {
          name: 'Mouse inalámbrico', icon: 'search-outline'
        },
        {
          name: 'Teclado gamer', icon: 'search-outline'
        },
        {
          name: 'Teclado inalámbrico', icon: 'search-outline'
        },
        {
          name: 'Teclado retroiluminado', icon: 'search-outline'
        },
        {
          name: 'Audifonos', icon: 'search-outline'
        },
      ];
  
      let container = [];
      let container2 = [];

      for(let i of data) {
        if(i.name.trim().normalize().toLowerCase()
        .indexOf(e.detail.value.trim().normalize().toLowerCase()) === 0) {
          container.push(i);
        }
        
        if(i.name.trim().normalize().toLowerCase()
        .indexOf(e.detail.value.trim().normalize().toLowerCase()) !== -1) {
          container2.push(i);
        }
      }

      if(container.length !== 0) {
        if(this.suggestSearch.length !== container.length) {
          this.suggestSearch = container.sort();
        }
      } else {
        if(this.suggestSearch.length !== container2.length) {
          this.suggestSearch = container2.sort();
        }
      }

    } else {
      this.suggestSearch = [];
    }
  }

  openSuggestSearch(value) {
    let searchbar: any = document.getElementById('folder-searchbar');
    searchbar.value = value;
    this.triggerSearch(value, 'search');
    this.suggestSearch = [];
  }

  home() {
    this.router.navigate(["/Store/Home"]);
  }
}
