import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-slider',
  templateUrl: './slider.component.html',
  styleUrls: ['./slider.component.scss'],
})
export class SliderComponent implements OnInit {
  a = 'background: #fff url("';
  b = '") no-repeat center center / cover;';

  topItemImage = "../../assets/images/images_desktop/camera1.png";
  bottomItemImage = "../../assets/images/images_desktop/camera2.png";

  swiperImages = [
    {
      src: '../../assets/images/images_desktop/slider-image01.png', 
      mobile: '../../assets/images/images_desktop/slider-image01.png', 
      desktop: '../../assets/images/images_desktop/slider-image01.png'
    },
    {
      src: '../../assets/images/images_desktop/slider-image2.jpg', 
      mobile: '../../assets/images/images_desktop/slider-image2.jpg', 
      desktop: '../../assets/images/images_desktop/slider-image2.jpg'
    },
    {
      src: 'https://mousegamer.club/wp-content/uploads/2018/07/Astro-A20.jpg', 
      mobile: 'https://mousegamer.club/wp-content/uploads/2018/07/Astro-A20.jpg', 
      desktop: 'https://mousegamer.club/wp-content/uploads/2018/07/Astro-A20.jpg'
    },
    {
      src: 'https://gamerstyle.com.mx/wp-content/uploads/2022/04/Xbox-One-Control.jpg', 
      mobile: 'https://gamerstyle.com.mx/wp-content/uploads/2022/04/Xbox-One-Control.jpg', 
      desktop: 'https://gamerstyle.com.mx/wp-content/uploads/2022/04/Xbox-One-Control.jpg'
    }
  ];
  constructor() { }

  ngOnInit() {
    // setTimeout(() => {
    //   this.moveSlides();
    // }, 1000);
  }

  moveSlides() {
    let swiper: any = document.getElementById('store_swiper');
    if(swiper !== null && swiper !== undefined) {
      swiper.swiper.slideNext(400);
    }
    setTimeout(() => {
      this.moveSlides()
    }, 5000);
  }

}
