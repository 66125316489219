import { Component, OnInit } from '@angular/core';
import { StructureService } from 'src/app/services/structure/structure.service';
import { LanguagesService } from 'src/app/services/languages/languages.service';
import { ThemeService } from 'src/app/services/theme/theme.service';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { AppComponent } from 'src/app/app.component';
import { Storage } from '@ionic/storage';
import { LoadingController } from '@ionic/angular';
import { FolderPage } from 'src/app/folder/folder.page';

@Component({
  selector: 'app-purchase-order-cart',
  templateUrl: './purchase-order-cart.component.html',
  styleUrls: ['./purchase-order-cart.component.scss'],
})
export class PurchaseOrderCartComponent implements OnInit {
  menuId = 'purchase-cart';
  isMenuOpen = false;
  isLoading = false;
  menuSelection = 0;
  idFilterSelected = 'all';
  itemsIter: any = [];

  anims = {
    top: {
      sizeup:'height:100%;',
      close: 'content-menu close-menu', 
      open: 'content-menu open-menu '
    }, 
    top80: {
      sizeup:'height: 80%;',
      close: 'content-menu close-menu', 
      open: 'content-menu open-menu'
    },
    bottom80: {
      sizeup:'height: 80%;',
      close: 'content-menu close-menu-bottom', 
      open: 'content-menu open-menu-bottom'
    },
    bottom: {
      sizeup:'height:100%;',
      close: 'content-menu close-menu-bottom', 
      open: 'content-menu open-menu-bottom'
    },
  };


  constructor(
    private structure: StructureService,
    private lenguages: LanguagesService,
    private theme: ThemeService,
    private sanitizer: DomSanitizer,
    private router: Router,
    private appComponet: AppComponent,
    private storage: Storage,
    private loadingController: LoadingController
  ) { }

  ngOnInit() {
    this.getCartItems();
  }


  public openButton (type, params) {
    let menu = document.getElementById("topMenu" + this.menuId);
    let menuBackground = document.getElementById("contenedor-principal" + this.menuId);
    if(type === 'bottom80'){
      menu.setAttribute('style','top: 20%;' + this.anims[type].sizeup);
    }else{
      menu.setAttribute('style','top: 0%;' + this.anims[type].sizeup);
    }
    if (this.isMenuOpen) {
      menu.className = this.anims[type].close;
      this.isMenuOpen = false;
      menuBackground.className = "menu menu-background-color-off";
    } else {
      menu.className = this.anims[type].open;
      this.isMenuOpen = true; 
      menuBackground.className = "menu menu-background-color-on";
      setTimeout(() => {
        this.getCartItems();
      }, 500);
    }
  }

  async getCartItems() {
    this.isLoading = true;
    this.storage.create();
    this.storage.get('user-uid').then((result) => {
      if(result !== undefined && result !== null) {
        this.appComponet.getCartProducts(result).then((response: any) => {
          let elBadge = document.getElementById('princ-badge-fold');      
          if(this.itemsIter.length !== response.length) {
            this.itemsIter = response;
          } else {
            if(this.itemsIter.length > 0) {
              for(let i in this.itemsIter) {
                if(this.itemsIter[i].stock !== response[i].stock) {
                  this.itemsIter = response;
                  break;
                }
              }
            }
          }
          elBadge.innerHTML = this.itemsIter.length.toString();      
          this.storage.set('cartItems', this.itemsIter.length);
                     
          setTimeout(() => {
            this.isLoading = false;
          }, 500);
        });
      }
    });
  }

  navigateItem(id) {
    this.router.navigate(['/product-id/' + id]);
    // this.openButton(this.theme.styleMenuCart, null);
    
    setTimeout(() => {
      //this.router.navigate(['/product-id/' + id]);
      this.openButton(this.theme.styleMenuCart, null);
    }, 300);
  }
}
