import { Injectable, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { MenuController } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class ThemeService implements OnInit{
  public imageMenu = '../assets/images/images_mobile/standart/store.png';
  public imageMenuWebp = '../assets/images/images_mobile/standart/store.png';

  public primaryColor = '#ff9000;';
  public primaryColorRgb = '255, 144, 0;';
  public primaryContrast = '#ffffff;';
  public primaryContrastRgb = '255, 255, 255;';
  public primaryShade = '#ffa300;';
  public primaryTint = '#ffb000;';
  public primaryColorSelectOpacity = '#ff900020;';

  public background = '#ffffff;';
  public headerColor = '#ffffff;';

  public menuButton = '#ffffff;';
  public cartButton = '#ffffff;';
  public badgerColor = '#ffffff;';
  public badgerText = '#ffffff';

  public searchBarColor = '#f4f5f8;';
  public searchBarColorRgb = '44, 245, 248;';
  public searchBarContrast = '#000000;';
  public searchBarContrastRgb = '0, 0, 0;';
  public searchBarShade = '#d7d8da;';
  public searchBarTint = '#f5f6f9;';
  public searchBarRadius = '3px;';

  public cardColor = '#f4f5f8;';
  public cardColorRgb = '44, 245, 248;';
  public cardContrast = '#000000;';
  public cardContrastRgb = '0, 0, 0;';
  public cardShade = '#d7d8da;';
  public cardTint = '#f5f6f9;';

  public cardImage = '#ffffff;';
  public cardMid = '#ffffff;';
  public cardPrice = '#000000;';
  public cardOffer = '#000000;';
  public cardOfferPercent = '#000000;';
  public cardDescription = '#000000';

  public cardItemColor = '#f4f5f8;';
  public cardItemColorRgb = '44, 245, 248;';
  public cardItemContrast = '#000000;';
  public cardItemContrastRgb = '0, 0, 0;';
  public cardItemShade = '#d7d8da;';
  public cardItemTint = '#f5f6f9;';

  public primaryColorButton = '#ff9000;';
  public primaryColorRgbButton = '255, 144, 0;';
  public primaryContrastButton = '#ffffff;';
  public primaryContrastRgbButton = '255, 255, 255;';
  public primaryShadeButton = '#ffa300;';
  public primaryTintButton = '#ffb000;';

  public styleMenu = 'overlay';
  public typeMenu = 'side';
  public styleMenuCart = 'top';
  public typeMenuCart = 'top';

  public styleIcon = '';
  constructor(private sanitizer: DomSanitizer, private menuController: MenuController) { }

  ngOnInit() {

  }

  /*------------ICON STYLE--------------*/
  public setStyleIcons(data) {
    this.styleIcon = data;
  }

  /*------------APP COMPONENT--------------*/
  public setSettings(data) {
    this.styleIcon = data.options.theme.styleIcons;
    this.background = data.options.theme.background;
    
    this.primaryColor = data.options.theme.menu.primaryColor;
    this.primaryColorRgb = data.options.theme.menu.primaryColorRgb;
    this.primaryContrast = data.options.theme.menu.primaryContrast;
    this.primaryContrastRgb = data.options.theme.menu.primaryContrastRgb;
    this.primaryShade = data.options.theme.menu.primaryShade;
    this.primaryTint = data.options.theme.menu.primaryTint;
    this.primaryColorSelectOpacity = data.options.theme.menu.primaryColorSelectOpacity;
   
    this.headerColor = data.options.theme.header.headerColor;
    this.menuButton = data.options.theme.header.menuButton;
    this.cartButton = data.options.theme.header.cartButton;
    this.badgerColor = data.options.theme.header.badger.background;
    this.badgerText = data.options.theme.header.badger.text;

    this.searchBarColor = data.options.theme.header.searchbar.color;
    this.searchBarColorRgb = data.options.theme.header.searchbar.colorRgb;
    this.searchBarContrast = data.options.theme.header.searchbar.contrast;
    this.searchBarContrastRgb = data.options.theme.header.searchbar.contrastRgb;
    this.searchBarShade = data.options.theme.header.searchbar.shade;
    this.searchBarTint = data.options.theme.header.searchbar.tint;
    this.searchBarRadius = data.options.theme.header.searchbar.radius;

    this.cardColor = data.options.theme.card.color;
    this.cardColorRgb = data.options.theme.card.colorRgb;
    this.cardContrast = data.options.theme.card.contrast;
    this.cardContrastRgb = data.options.theme.card.contrastRgb;
    this.cardShade = data.options.theme.card.shade;
    this.cardTint = data.options.theme.card.tint;

    this.cardImage =  data.options.theme.card.image;
    this.cardMid =  data.options.theme.card.mid;
    this.cardPrice =  data.options.theme.card.price;
    this.cardOffer = data.options.theme.card.dcto;
    this.cardOfferPercent = data.options.theme.card.dctoPercent;
    this.cardDescription =  data.options.theme.card.description;

    this.cardItemColor = data.options.theme.card.item.color;
    this.cardItemColorRgb = data.options.theme.card.item.colorRgb;
    this.cardItemContrast = data.options.theme.card.item.contrast;
    this.cardItemContrastRgb = data.options.theme.card.item.contrastRgb;
    this.cardItemShade = data.options.theme.card.item.shade;
    this.cardItemTint = data.options.theme.card.item.tint;

    this.styleMenu = 'top';
    this.typeMenu = 'top';
    
    this.styleMenuCart = 'top';
    this.typeMenuCart = 'top';

    if(this.typeMenu === 'side') {
      this.menuController.enable(true);
    } else {
      this.menuController.enable(false);
    }
  }

  public setThemeItemMenu(){
    return this.sanitizer.bypassSecurityTrustStyle(
      '--ion-color-primary: ' + this.primaryColor + 
      '--ion-color-primary-rgb: ' + this.primaryColorRgb +
      '--ion-color-primary-contrast: ' + this.primaryContrast +
      '--ion-color-primary-contrast-rgb: ' + this.primaryContrastRgb +
      '--ion-color-primary-shade: '  + this.primaryShade +
      '--ion-color-primary-tint: ' + this.primaryTint );
  }

  public setThemeButton(){
    return this.sanitizer.bypassSecurityTrustStyle(
      '--ion-color-primary: ' + this.primaryColorButton + 
      '--ion-color-primary-rgb: ' + this.primaryColorRgbButton +
      '--ion-color-primary-contrast: ' + this.primaryContrastButton +
      '--ion-color-primary-contrast-rgb: ' + this.primaryContrastRgbButton +
      '--ion-color-primary-shade: '  + this.primaryShadeButton +
      '--ion-color-primary-tint: ' + this.primaryTintButton);
  }

  public setThemeIcon() {
    return 'color: ' + this.primaryColorButton;
  }

  public setThemeColor(){
    return this.sanitizer.bypassSecurityTrustStyle(
      '--ion-color-base: ' + this.primaryColor + 
      '--ion-color-base-rgb: ' + this.primaryColorRgb +
      '--ion-color-contrast: ' + this.primaryContrast +
      '--ion-color-contrast-rgb: ' + this.primaryContrastRgb +
      '--ion-color-shade: '  + this.primaryShade +
      '--ion-color-tint: ' + this.primaryTint );
  }

  public setProductTheme(){
    return this.sanitizer.bypassSecurityTrustStyle(
      '--ion-color-base: ' + this.primaryColor + 
      '--ion-color-base-rgb: ' + this.primaryColorRgb +
      '--ion-color-contrast: ' + this.primaryContrast +
      '--ion-color-contrast-rgb: ' + this.primaryContrastRgb +
      '--ion-color-shade: '  + this.primaryShade +
      '--ion-color-tint: ' + this.primaryTint );
  }

  public setFilterOn() {
    return 'color: ' + this.primaryColor +
           '--background: ' + this.primaryContrast;
  }
  public setFilterIconOn() {
    return 'color: ' + this.primaryColor;
  }

  public setFilterOff() {
    return 'color: none' +
           '--background: ' + this.primaryContrast;
  }
  public setFilterIconOff() {
    return 'color: none';
  }

  /*------------FOLDER CARD PRODUCTS--------------*/

  public setThemeProductCardColor(){
    return this.sanitizer.bypassSecurityTrustStyle(
      '--ion-color-base: ' + this.cardColor + 
      '--ion-color-base-rgb: ' + this.cardColorRgb +
      '--ion-color-contrast: ' + this.cardContrast +
      '--ion-color-contrast-rgb: ' + this.cardContrastRgb +
      '--ion-color-shade: '  + this.cardShade +
      '--ion-color-tint: ' + this.cardTint);
  }

  public setThemeProductCardImage(){
    return this.sanitizer.bypassSecurityTrustStyle('background-color:' + this.cardImage);
  }
  public setThemeProductCardMid(){
    return this.sanitizer.bypassSecurityTrustStyle('background-color: ' + this.cardMid);
  }
  public setThemeProductCardPrice(){
    return this.sanitizer.bypassSecurityTrustStyle('color: ' + this.cardPrice);
  }

  public setThemeProductCardOffer(){
    return this.sanitizer.bypassSecurityTrustStyle('color: ' + this.cardOffer);
  }

  public setThemeProductCardOfferPercent(){
    return this.sanitizer.bypassSecurityTrustStyle('color: ' + this.cardOfferPercent);
  }

  public setThemeProductCardDescription(){
    return this.sanitizer.bypassSecurityTrustStyle('color: ' + this.cardDescription);
  }
  public setThemeProductCardItem(){
    return this.sanitizer.bypassSecurityTrustStyle(
      '--ion-color-base: ' + this.cardItemColor + 
      '--ion-color-base-rgb: ' + this.cardItemColorRgb +
      '--ion-color-contrast: ' + this.cardItemContrast +
      '--ion-color-contrast-rgb: ' + this.cardItemContrastRgb +
      '--ion-color-shade: '  + this.cardItemShade +
      '--ion-color-tint: ' + this.cardItemTint);
  }

 /*------------////////////////--------------*/


 /*------------FOLDER HEADER --------------*/
  public setMenuButtonFolder() {
    return this.sanitizer.bypassSecurityTrustStyle('color: ' + this.menuButton);
  }

  public setCartFolder() {
    return this.sanitizer.bypassSecurityTrustStyle('color: ' + this.cartButton);
  }

  public setBadgerFolder() {
    return this.sanitizer.bypassSecurityTrustStyle(
      'background-color: '+ this.badgerColor + 
      'color: ' + this.badgerText);
  }

  public setSearchBarFolder() {
    return this.sanitizer.bypassSecurityTrustStyle(
      '--ion-color-base: ' + this.searchBarColor + 
      '--ion-color-base-rgb: ' + this.searchBarColorRgb +
      '--ion-color-contrast: ' + this.searchBarContrast +
      '--ion-color-contrast-rgb: ' + this.searchBarContrastRgb +
      '--ion-color-shade: '  + this.searchBarShade +
      '--ion-color-tint: ' + this.searchBarTint + 
      '--border-radius: ' + this.searchBarRadius);
  }
 /*------------////////////////--------------*/
  
 
 /*------------FOLDER--------------*/
  public setBackgroundFolder() {
    return this.sanitizer.bypassSecurityTrustStyle('--background: ' + this.background);
  }

  public setHeaderFolder() {
    return this.sanitizer.bypassSecurityTrustStyle('background-color: ' + this.headerColor);
  }

  public setIconPrimaryColor(){
    return this.sanitizer.bypassSecurityTrustStyle('color: ' + this.primaryColor);
  }

  public setImageItemModel(image){
    if(image !== undefined){
      return this.sanitizer.bypassSecurityTrustStyle('background: #fff url("'+ image[0] +'") no-repeat center center / cover;');
    }
  }
}
