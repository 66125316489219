import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SliderComponent } from './slider/slider.component';
import { SwiperModule } from 'swiper/angular';
import { SideMenuPcComponent } from './side-menu-pc/side-menu-pc.component';
import { RouterModule } from '@angular/router';
import { TopMenuMobileComponent } from './top-menu-mobile/top-menu-mobile.component';
import { PurchaseOrderCartComponent } from './purchase-order-cart/purchase-order-cart.component';

@NgModule({
  declarations: [
    SliderComponent, 
    SideMenuPcComponent,
    TopMenuMobileComponent,
    PurchaseOrderCartComponent
  ],
  imports: [
    CommonModule,
    SwiperModule,
    RouterModule
  ],
  exports: [
    SliderComponent, 
    SideMenuPcComponent,
    TopMenuMobileComponent,
    PurchaseOrderCartComponent
  ]
})
export class ComponentsModule { }
