import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class StructureService {

  public menuStructure = { 
    buttons: [
      {icon: 'star', text: 'hola'},{icon: 'star', text: 'xD'},
      {icon: 'star', text: 'Heyyy'}, {icon: 'star', text: 'hola'},
            ],
    headerImage: 'https://cdn-icons-png.flaticon.com/512/776/776645.png'
    };

  constructor() { }
}
