import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'Store/Home',
    pathMatch: 'full'
  },
  {
    path: 'Store/:id',
    loadChildren: () => import('./folder/folder.module').then( m => m.FolderPageModule)
  },
  {
    path: 'product-id/:id',
    loadChildren: () => import('./product-id/product-id.module').then( m => m.ProductIdPageModule)
  },
  {
    path: 'test-developer',
    loadChildren: () => import('./test-developer/test-developer.module').then( m => m.TestDeveloperPageModule)
  },
  {
    path: 'create-account/:id',
    loadChildren: () => import('./create-account/create-account.module').then( m => m.CreateAccountPageModule)
  },
  {
    path: 'my-account/:id',
    loadChildren: () => import('./my-account/my-account.module').then( m => m.MyAccountPageModule)
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
