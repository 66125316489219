import { Injectable } from '@angular/core';
import * as CryptoJS from "crypto-js";

@Injectable({
  providedIn: 'root'
})
export class HtppParserService {

  constructor() { }

  public async dcpayload  (data, payload, receive) {
    return await new Promise((resolve, reject) => {
      const ivR = payload.substr(40, 47) + payload.substr(0, 8);
      const keyR = payload.substr(8, 32)
      const key = CryptoJS.enc.Utf8.parse(keyR);
      const iv = CryptoJS.enc.Utf8.parse(ivR);
      if(receive) {
        let decrypted = CryptoJS.AES.decrypt(data, key, { iv: iv });
        let result = decrypted.toString(CryptoJS.enc.Utf8);
        const jsonData =  JSON.parse(result);
        resolve(jsonData);
      } else {
      // let encrypted = CryptoJS.AES.encrypt('', key, { iv: iv });
      // let redsencrypted = encrypted.toString();
      // console.log(redsencrypted);
      }
    });
  }
}
