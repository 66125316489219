import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LanguagesService {
  /*------------APP COMPONENT--------------*/
  public appPages = [
    { title: 'Inicio', url: '/Store/Home', icon: 'storefront', },
    { title: 'Mi cuenta', url: '/my-account/Preferences', icon: 'person', },
    { title: 'Carrito de compras', url: '/Store/Purchase-order', icon: 'cart', function: true},
    { title: 'Favoritos', url: '/my-account/Favorites', icon: 'heart', function: true},
    { title: 'Historial de Compras', url: '/my-account/Shopping', icon: 'bag-handle', },
    { title: 'Historial', url: '/my-account/Historial', icon: 'time', },
    { title: 'Ofertas', url: '/Store/Offers', icon: 'pricetags', },
  ];

  public filter =  { title: 'Filtrar', icon: 'search', type: 'button' };
  public tag_filters =  [
    { title: 'Todos', icon: 'bookmark', id: 'all'},
    { title: 'Menor precio', icon: 'bookmark', id: 'menor'},
    { title: 'Mayor precio', icon: 'bookmark', id: 'mayor'}
  ];

  public categories = [
    { title: 'Carros', url: '/Store/Carros', icon: 'home' },
    { title: 'Motos', url: '/Store/Motos', icon: 'paper-plane' },
    { title: 'Varcos', url: '/Store/Varcos', icon: 'heart' },
    { title: 'Aviones', url: '/Store/Aviones', icon: 'archive' },
    { title: 'Ciclas', url: '/Store/Ciclas', icon: 'trash' },
    { title: 'Camiones', url: '/Store/Camiones', icon: 'warning' },
  ];

  public st_menu_categories = 'Categorías';
  /*------------FOLDER--------------*/
  public st_searchBar = 'Buscar';
  constructor() { }

  public setCategories(data) {
    this.categories = [];
    for(let i of data.categories) {
      this.categories.push({ title: i.name, url: '/Store/' + i.name, icon: i.icon });
    }
  }

  public setLenguageAtmosphere(){
    return this.categories;
  }
}
