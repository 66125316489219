import { Component, OnInit } from '@angular/core';
import { StructureService } from 'src/app/services/structure/structure.service';
import { LanguagesService } from 'src/app/services/languages/languages.service';
import { ThemeService } from 'src/app/services/theme/theme.service';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';

@Component({
  selector: 'app-top-menu-mobile',
  templateUrl: './top-menu-mobile.component.html',
  styleUrls: ['./top-menu-mobile.component.scss'],
})
export class TopMenuMobileComponent implements OnInit {
  menuId = 'topMenuMobile';
  isMenuOpen = false;
  menuSelection = 0;
  idFilterSelected = 'all';

  anims = {
    top: {
      sizeup:'height:100%;',
      close: 'content-menu close-menu', 
      open: 'content-menu open-menu '
    }, 
    top80: {
      sizeup:'height: 80%;',
      close: 'content-menu close-menu', 
      open: 'content-menu open-menu'
    },
    bottom80: {
      sizeup:'height: 80%;',
      close: 'content-menu close-menu-bottom', 
      open: 'content-menu open-menu-bottom'
    },
    bottom: {
      sizeup:'height:100%;',
      close: 'content-menu close-menu-bottom', 
      open: 'content-menu open-menu-bottom'
    },
  };

  constructor (
    private structure: StructureService,
    private lenguages: LanguagesService,
    private theme: ThemeService,
    private sanitizer: DomSanitizer,
    private router: Router
    ) { }

  ngOnInit() {}

  loadPage(index, route) {
    console.log(index, route);
    let afterButton = document.getElementById('item-' + this.menuSelection);
    let beforeButton = document.getElementById('item-' + index);
    afterButton.className = 'theme-color item-has-start-slot item md item-lines-none item-fill-none in-list hydrated item-label';
    beforeButton.className = 'theme-color item-selected item-has-start-slot item md item-lines-none item-fill-none in-list hydrated item-label';
    this.menuSelection = index;
    this.router.navigate([route]);
    this.openButton(this.theme.styleMenu, null);
  }

  public openButton (type, params) {
    let menu = document.getElementById("topMenu");
    let menuBackground = document.getElementById("contenedor-principal");
    if(type === 'bottom80'){
      menu.setAttribute('style','top: 20%;' + this.anims[type].sizeup);
    }else{
      menu.setAttribute('style','top: 0%;' + this.anims[type].sizeup);
    }
    if (this.isMenuOpen) {
      menu.className = this.anims[type].close;
      this.isMenuOpen = false;
      menuBackground.className = "menu menu-background-color-off";
    } else {
      menu.className = this.anims[type].open;
      this.isMenuOpen = true; 
      menuBackground.className = "menu menu-background-color-on";
    }
  }

  filters(){
    let content = document.getElementById('filter-container' + this.menuId);
    console.log(content.className);
    if(content.className === 'filter-items filters-animation-open'){
      content.className = 'filter-items filters-animation-close';

      document.getElementById('filter-button' + this.menuId).setAttribute('style', this.theme.setFilterOff());
      document.getElementById('filter-icon' + this.menuId).setAttribute('style', this.theme.setFilterIconOff());

    } else {
      content.className = 'filter-items filters-animation-open';
      document.getElementById('filter-button' + this.menuId).setAttribute('style', this.theme.setFilterOn());
      document.getElementById('filter-icon' + this.menuId).setAttribute('style', this.theme.setFilterIconOn());
    }
  }

  selectFilter(id){
    this.closeFilters();
    this.idFilterSelected = id;
    this.openButton(this.theme.styleMenu, {});
  }

  closeFilters(){
    let content: any = document.getElementById('filter-container' + this.menuId);
    if(content.className === 'filter-items filters-animation-open'){
      content.className = 'filter-items filters-animation-close';  
      document.getElementById('filter-button' + this.menuId).setAttribute('style', this.theme.setFilterOff());
      document.getElementById('filter-icon' + this.menuId).setAttribute('style', this.theme.setFilterIconOff());
    }
    setTimeout(() => {
      content.className = 'filter-items'
    }, 400);
  }

}
