import { Component, OnInit } from '@angular/core';
import { StructureService } from 'src/app/services/structure/structure.service';
import { LanguagesService } from 'src/app/services/languages/languages.service';
import { ThemeService } from 'src/app/services/theme/theme.service';
import { FolderPage } from 'src/app/folder/folder.page';
@Component({
  selector: 'app-side-menu-pc',
  templateUrl: './side-menu-pc.component.html',
  styleUrls: ['./side-menu-pc.component.scss'],
})
export class SideMenuPcComponent implements OnInit {
  isMenuOpen = false;
  menuSelection = 0;

  constructor(private structure: StructureService, private lenguages: LanguagesService,
              private theme: ThemeService, private folder: FolderPage) { }

  ngOnInit() {}

  openButton() {
    let button = document.getElementById('open-button');
    this.folder.getMenuButtonPC(this.isMenuOpen);
    if(this.isMenuOpen) {
      button.className = 'icon-rotate-open md hydrated';
      this.isMenuOpen = false;
    } else {
      button.className = 'icon-rotate-close md hydrated';
      this.isMenuOpen = true;
    }
  }

  loadPage(index, route) {
    console.log(index, route);
    let afterButton = document.getElementById('item-' + this.menuSelection);
    let beforeButton = document.getElementById('item-' + index);
    console.log(afterButton.className);
    afterButton.className = 'theme-color item-has-start-slot item md item-lines-none item-fill-none in-list ion-activatable ion-focusable hydrated item-labe';
    beforeButton.className = 'theme-color item-selected item-has-start-slot item md item-lines-none item-fill-none in-list ion-activatable ion-focusable hydrated item-labe';
    
    this.menuSelection = index;
  }
}
